@keyframes background-text-animation {
  0% {
    background-position: left 0px top 50%;
  }
  50% {
    background-position: left 1500px top 50%;
  }
  100% {
    background-position: left 0px top 50%;
  }
}

.animated-text {
  color: rgba(4, 135, 33, 0.1);

  background-size: cover;
  background-image: url("../assets/images/bg.png");
  -webkit-background-clip: text;
  background-clip: text;
  animation: background-text-animation 10s linear infinite;
}

.scrolling-alert {
  height: 50px;
  overflow: hidden;
  position: relative;
}
.scrolling-alert h3 {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;

  /* Starting position */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  /* Apply animation to this element */
  -moz-animation: scrolling-alert 5s linear infinite;
  -webkit-animation: scrolling-alert 5s linear infinite;
  animation: scrolling-alert 5s linear infinite;
}

/* Move it (define the animation) */
@-moz-keyframes scrolling-alert {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}
@-webkit-keyframes scrolling-alert {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes scrolling-alert {
  0% {
    -moz-transform: translateX(100%); /* Firefox bug fix */
    -webkit-transform: translateX(100%); /* Firefox bug fix */
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%); /* Firefox bug fix */
    -webkit-transform: translateX(-100%); /* Firefox bug fix */
    transform: translateX(-100%);
  }
}
.pdf-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  background-color: #eeeeee;
  width: 100%;
  box-sizing: border-box;
}

canvas.react-pdf__Page__canvas {
  margin-bottom: 50px;
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .pdf-div {
    padding: 5px;
  }

  canvas.react-pdf__Page__canvas {
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .pdf-div {
    padding: 5px;
  }
}
